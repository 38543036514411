import { Fragment, useRef, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import AuthContext from "../AuthContext";
import Axios from "../api/api";
import { toast } from "react-toastify";

export default function AddSign({
  hideSignModal,
  handlePageUpdate,
  isEdit,
  currentSign,
}) {
  const [sign, setSign] = useState({
    id: currentSign ? currentSign._id : "",
    name: currentSign ? currentSign.name : "",
    type: currentSign ? currentSign.type : "",
    description: currentSign ? currentSign.description : "",
    image: currentSign ? currentSign.image : "",
  });

  const auth = useContext(AuthContext);
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const fileInputRef = useRef(null);

  // Handling Input Change for input fields
  const handleInputChange = (key, value) => {
    setSign({ ...sign, [key]: value });
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  // POST Data
  const saveSign = () => {
    if (sign.name === "") {
      toast("Please input name", { type: "info" });
      return;
    }
    let url = "";
    if (isEdit) url = "/api/signs/edit";
    else url = "/api/signs/add";

    Axios(auth)
      .post(url, sign)
      .then((result) => {
        handlePageUpdate();
        hideSignModal();
      })
      .catch((err) => {
        toast("Something is wrong, please try again", { type: "error" });
      });
  };

  return (
    // Modal
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-lg  py-4 font-semibold leading-6 text-gray-900 flex items-center"
                      >
                        <div className="mx-auto flex h-12 items-center justify-center rounded-full bg-blue-100 sm:h-10 sm:w-10 mr-4">
                          <PlusIcon
                            className="h-6 w-6 text-blue-400"
                            aria-hidden="true"
                          />
                        </div>
                        <span className="grow">
                          {isEdit ? "Edit" : "Add"} Sign
                        </span>
                      </Dialog.Title>
                      <form action="#">
                        <div className="grid gap-4 mb-4 sm:grid-cols-1">
                          <div>
                            <label
                              htmlFor="sign-image"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                              Sign Image
                            </label>
                            <input
                              type="file"
                              name="image"
                              id="sign-image"
                              accept="image/*"
                              className="hidden"
                              ref={fileInputRef}
                              onChange={(e) =>
                                getBase64(e.target.files[0], (res) =>
                                  handleInputChange(e.target.name, res)
                                )
                              }
                            />
                            <div className="flex justify-center">
                              <img
                                src={
                                  sign.image
                                    ? sign.image
                                    : require("../assets/empty.jpg")
                                }
                                alt="Sign"
                                className="w-60 border-2 rounded-xl cursor-pointer"
                                onClick={handleImageClick}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="grid gap-4 mb-4 sm:grid-cols-1">
                          <div>
                            <label
                              htmlFor="sign-name"
                              className="block mb-2 text-sm font-medium text-gray-900"
                            >
                              Sign Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="sign-name"
                              value={sign.name}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                              placeholder="Name"
                            />
                          </div>
                        </div>

                        <div className="grid gap-4 mb-4 sm:grid-cols-1">
                          <div>
                            <label
                              htmlFor="sign-type"
                              className="block mb-2 text-sm font-medium text-gray-900"
                            >
                              Sign Type
                            </label>

                            <select
                              id="sign-type"
                              name="type"
                              aria-selected={Boolean(sign.type)}
                              value={sign.type}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 [&[aria-selected='false']]:text-gray-400"
                            >
                              <option disabled value="">
                                Sign Type
                              </option>
                              <option value="existing">Existing</option>
                              <option value="new">New</option>
                              <option value="hatch">Hatch</option>
                            </select>
                          </div>
                        </div>

                        <div className="grid gap-4 mb-4 sm:grid-cols-1">
                          <div>
                            <label
                              htmlFor="sign-description"
                              className="block mb-2 text-sm font-medium text-gray-900"
                            >
                              Description
                            </label>
                            <textarea
                              name="description"
                              id="sign-description"
                              value={sign.description}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                              placeholder="Description"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                    onClick={saveSign}
                  >
                    {isEdit ? "Save" : "Add"} Sign
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => hideSignModal()}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
