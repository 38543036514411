import { Fragment, useContext, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon, XCircleIcon } from "@heroicons/react/24/outline";
import html2canvas from "html2canvas";
import Axios from "../api/api";
import AuthContext from "../AuthContext";
import SignsModal from "./SignsModal";
import DragAndResizable from "./DragAndResizeable";

export default function EditImage({ onClose, onSave, open, sign, signs }) {
  const [isSelecting, setIsSelecting] = useState(false);
  const [isPositioning, setIsPositioning] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 });
  const [text, setText] = useState("");
  const [shape, setShape] = useState("square");
  const [fontSize, setFontSize] = useState("24");
  const [signsModal, setSignsModal] = useState(false);
  const [selectedSigns, setSelectedSigns] = useState([]);

  const editedDivRef = useRef(null);
  const auth = useContext(AuthContext);

  const handleSelectSign = (selected_sign) => {
    setSelectedSigns((prev) => [...prev, selected_sign]);
  };
  const handleRemoveSign = (name, index) => {
    setSelectedSigns(
      selectedSigns.filter((d, i) => i !== index || name !== d.name)
    );
  };

  const handleSave = async () => {
    html2canvas(editedDivRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      Axios(auth)
        .post("/api/signs/private/add", {
          name: sign.name + " " + text,
          image: imgData,
          type: sign?.type,
        })
        .then(() => {
          onSave();
          handleClose();
        })
        .catch((err) => console.log(err));
    });
  };

  const handleClose = () => {
    setIsSelecting(false);
    setIsPositioning(false);
    setStartPos({ x: 0, y: 0 });
    setCurrentPos({ x: 0, y: 0 });
    setSelectedSigns([]);
    setText("");
    onClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-lg  py-4 font-semibold leading-6 text-gray-900 flex items-center"
                      >
                        <div className="mx-auto flex h-12 items-center justify-center rounded-full bg-blue-100 sm:h-10 sm:w-10 mr-4">
                          <PlusIcon
                            className="h-6 w-6 text-blue-400"
                            aria-hidden="true"
                          />
                        </div>
                        <span className="grow">Edit Image</span>
                      </Dialog.Title>
                      <div className="w-full flex justify-center">
                        <div
                          className="relative overflow-hidden"
                          ref={editedDivRef}
                        >
                          <img
                            className="w-full max-w-64"
                            src={sign?.image}
                            alt="sign"
                          />
                          <div
                            onMouseDown={(e) => {
                              const rect =
                                e.currentTarget.getBoundingClientRect();

                              if (isSelecting) {
                                setIsPositioning(true);
                                setStartPos({
                                  x: Math.round(e.clientX - rect.x),
                                  y: Math.round(e.clientY - rect.y),
                                });
                              }
                            }}
                            onMouseMove={(e) => {
                              const rect =
                                e.currentTarget.getBoundingClientRect();

                              if (isSelecting && isPositioning) {
                                setCurrentPos({
                                  x: Math.round(e.clientX - rect.x),
                                  y: Math.round(e.clientY - rect.y),
                                });
                              }
                            }}
                            onMouseUp={() => {
                              if (isSelecting || isPositioning) {
                                setIsSelecting(false);
                                setIsPositioning(false);
                              }
                            }}
                            style={{
                              cursor: isSelecting ? "crosshair" : "default",
                            }}
                            className="w-full h-full absolute top-0 left-0"
                          >
                            <div
                              style={{
                                borderRadius: shape === "circle" ? "50%" : 0,
                                position: "absolute",
                                background: isPositioning
                                  ? "#00000080"
                                  : "#FFF",
                                top: startPos.y + "px",
                                left: startPos.x + "px",
                                width: currentPos.x - startPos.x + "px",
                                height: currentPos.y - startPos.y + "px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {!isPositioning && (
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: fontSize
                                      ? fontSize + "px"
                                      : "24px",
                                    textAlign: "center",
                                    lineHeight: 1.1,
                                  }}
                                >
                                  {text}
                                </p>
                              )}
                            </div>
                            {selectedSigns?.map((d) => (
                              <DragAndResizable backgroundImage={d.image} />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-4 flex gap-2 flex-col">
                  <div className="flex items-center flex-wrap gap-2">
                    {selectedSigns.map((d, i) => (
                      <div
                        className="flex items-center gap-2 px-2 py-0.5 bg-slate-200 text-xs rounded-full w-fit font-medium"
                        key={i}
                      >
                        {d.name}{" "}
                        <XCircleIcon
                          className="h-5 w-5 cursor-pointer"
                          onClick={() => handleRemoveSign(d.name, i)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-row gap-2 items-center">
                    <button
                      onClick={() => setSignsModal(true)}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Select Sign
                    </button>
                    <button
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setIsSelecting(true)}
                    >
                      {isSelecting ? "Selecting" : "Select Area"}
                    </button>
                    <input
                      checked={shape === "square"}
                      onChange={(e) => setShape(e.target.value)}
                      type="radio"
                      id="square"
                      name="shape"
                      value="square"
                    />
                    <label for="square">Square</label>
                    <input
                      checked={shape === "circle"}
                      onChange={(e) => setShape(e.target.value)}
                      type="radio"
                      id="circle"
                      name="shape"
                      value="circle"
                    />
                    <label for="circle">Circle</label>
                  </div>
                  <input
                    placeholder="Text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                  />
                  <div className="grid grid-cols-3 w-full gap-2">
                    <label className="flex-1">
                      Text Size <br />
                      <input
                        type="number"
                        className="w-full"
                        placeholder="Text Size"
                        value={fontSize}
                        onChange={(e) => setFontSize(e.target.value)}
                      />
                    </label>

                    <label className="flex-1">
                      Position Top <br />
                      <input
                        type="number"
                        className="w-full"
                        placeholder="Text Size"
                        value={startPos.y}
                        onChange={(e) => {
                          setStartPos((prev) => ({
                            ...prev,
                            y: e.target.value,
                          }));
                          setCurrentPos((prev) => ({
                            ...prev,
                            y: prev.y + (e.target.value - startPos.y),
                          }));
                        }}
                      />
                    </label>

                    <label className="flex-1">
                      Position Left <br />
                      <input
                        type="number"
                        className="w-full"
                        placeholder="Text Size"
                        value={startPos.x}
                        onChange={(e) => {
                          setStartPos((prev) => ({
                            ...prev,
                            x: e.target.value,
                          }));
                          setCurrentPos((prev) => ({
                            ...prev,
                            x: prev.x + (e.target.value - startPos.x),
                          }));
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                    onClick={handleSave}
                  >
                    Save Image
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
                <SignsModal
                  open={signsModal}
                  onClose={() => setSignsModal(false)}
                  onSelect={handleSelectSign}
                  signs={signs}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
