import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import SideMenu from "./SideMenu";
import AuthContext from "../AuthContext";

function Layout() {
  const authContext = useContext(AuthContext);
  const isAdmin = authContext.userData.role === 'admin';
  return (
    <>
      <div className="md:h-16 fixed top-0 w-full z-40">
        <Header />
      </div>
      <div className="grid grid-cols-12 bg-gray-100">
        { isAdmin && (<div className="col-span-2 h-screen sticky top-0 hidden lg:flex pt-16">
            <SideMenu />
          </div>) }
        <div className={ isAdmin ? "col-span-12 lg:col-span-10  flex justify-center pt-16" : "col-span-12 flex justify-center min-h-screen pt-20 px-5"}>
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Layout;
