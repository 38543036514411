import React, { Fragment, memo, useEffect, useMemo, useState } from "react";
import { Layer, Marker, Source } from "react-map-gl";
import Arrow from "../assets/arrow.svg";
import Text from "../assets/text.svg";
import ArrowPng from "../assets/arrow.png";

const MapLineDrawer = ({
  viewport,
  clickCoordinates,
  hoverCoordinates,
  lines,
  setLines,
  hideControls,
}: any) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const selectedLine = useMemo(() => {
    const selected = lines?.find((line) => line?.selected);
    return selected;
  }, [lines]);

  const addArrowOnLine = (position) => {
    let newLine = { ...selectedLine };
    if (position === "left") {
      newLine = { ...newLine, leftArrow: !selectedLine?.leftArrow };
    }
    if (position === "right") {
      newLine = { ...newLine, rightArrow: !selectedLine?.rightArrow };
    }

    setLines(lines.map((line) => (line?.id === newLine?.id ? newLine : line)));
  };

  const calculateRotation = (start, end) => {
    const dx = end[0] - start[0];
    const dy = end[1] - start[1];
    return Math.atan2(dy, dx) * (180 / Math.PI);
  };

  const transform: any = (start, end, onlyRotation) => {
    const rotation = calculateRotation(start, end) - viewport.bearing;
    if (onlyRotation) return rotation;
    return `rotate(${rotation}deg)`;
  };

  useEffect(() => {
    if (clickCoordinates?.lat === null && clickCoordinates?.lng === null) {
      setIsDrawing(false);
      setCoordinates([]);
      setLines((prev) => [
        ...prev,
        { id: new Date().toLocaleString(), coordinates },
      ]);
    } else if (isDrawing) {
      setCoordinates([
        ...coordinates,
        [clickCoordinates.lng, clickCoordinates.lat],
      ]);
    }
  }, [clickCoordinates]);

  return (
    <Fragment>
      <style>{`
      .mapboxgl-canvas {
      cursor:${isDrawing ? "crosshair " : "pointer "} !important
      }
      `}</style>
      <div
        style={{
          position: "absolute",
          top: "56px",
          left: "10px",
          display: hideControls ? "none" : "flex",
          gap: "8px",
        }}
      >
        <div
          onClick={() => setIsDrawing(!isDrawing)}
          style={{
            width: "32px",
            height: "32px",
            background: "white",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <img
            style={{ height: "24px", width: "24px" }}
            src='data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20">%3Cpath d="m13.5 3.5c-1.4 0-2.5 1.1-2.5 2.5 0 .3 0 .6.2.9l-3.8 3.8c-.3-.1-.6-.2-.9-.2-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5c0-.3 0-.6-.2-.9l3.8-3.8c.3.1.6.2.9.2 1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5z"/>%3C/svg>'
          />
        </div>
        {selectedLine && (
          <div
            onClick={() => addArrowOnLine("left")}
            style={{
              width: "32px",
              height: "32px",
              background: "white",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              transform: "rotate(-90deg)",
            }}
          >
            <img style={{ height: "24px", width: "24px" }} src={ArrowPng} />
          </div>
        )}
        {selectedLine && (
          <div
            onClick={() => addArrowOnLine("right")}
            style={{
              width: "32px",
              height: "32px",
              background: "white",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              transform: "rotate(90deg)",
            }}
          >
            <img style={{ height: "24px", width: "24px" }} src={ArrowPng} />
          </div>
        )}
        {selectedLine && (
          <div
            onClick={() => {
              const text = window.prompt("Please enter text for selected line");
              setLines(
                lines.map((line) =>
                  line?.id === selectedLine?.id
                    ? { ...selectedLine, text }
                    : line
                )
              );
              console.log("text: ", lines);
            }}
            style={{
              width: "32px",
              height: "32px",
              background: "white",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <img style={{ height: "24px", width: "24px" }} src={Text} />
          </div>
        )}
      </div>
      <Source
        id="polylineLayer"
        type="geojson"
        data={{
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates:
              coordinates?.length > 0 && isDrawing
                ? [
                    ...coordinates,
                    [hoverCoordinates?.lng, hoverCoordinates?.lat],
                  ]
                : coordinates,
          },
        }}
      >
        <Layer
          id="lineLayer"
          type="line"
          source="my-data"
          layout={{
            "line-join": "round",
            "line-cap": "round",
          }}
          paint={{
            "line-color": "rgba(0, 50, 250, 0.75)",
            "line-width": 2,
            "line-dasharray": [2, 2],
          }}
        />
      </Source>
      {lines?.length > 0 &&
        lines?.map((line) => (
          <Fragment key={"Line-" + line?.id}>
            {line?.coordinates?.length > 0 && (
              <>
                {line?.text && viewport?.zoom > 17 && (
                  <Source
                    id={"textSource" + line?.id}
                    type="geojson"
                    data={{
                      type: "FeatureCollection",
                      features: [
                        {
                          type: "Feature",
                          geometry: {
                            type: "Point",
                            coordinates: [
                              (line?.coordinates?.[
                                line?.coordinates?.length - 2
                              ]?.[0] +
                                line?.coordinates?.[
                                  line?.coordinates?.length - 1
                                ]?.[0]) /
                                2,
                              (line?.coordinates?.[
                                line?.coordinates?.length - 2
                              ]?.[1] +
                                line?.coordinates?.[
                                  line?.coordinates?.length - 1
                                ]?.[1]) /
                                2,
                            ],
                          },
                          properties: {
                            title: line?.text,
                          },
                        },
                      ],
                    }}
                  >
                    <Layer
                      id={"textLayer" + line?.id}
                      type="symbol"
                      layout={{
                        "text-field": "{title}",
                        "text-offset": [0, 0.1],
                        "text-anchor": "top",
                        "text-rotate":
                          transform(
                            [
                              line?.coordinates?.[
                                line?.coordinates?.length - 2
                              ]?.[1],
                              line?.coordinates?.[
                                line?.coordinates?.length - 2
                              ]?.[0],
                            ],
                            [
                              line?.coordinates?.[
                                line?.coordinates?.length - 1
                              ]?.[1],
                              line?.coordinates?.[
                                line?.coordinates?.length - 1
                              ]?.[0],
                            ],
                            true
                          ) - 90,
                      }}
                    />
                  </Source>
                )}

                {line?.rightArrow && viewport?.zoom > 17 && (
                  <Marker
                    latitude={
                      line?.coordinates?.[line?.coordinates?.length - 1]?.[1]
                    }
                    longitude={
                      line?.coordinates?.[line?.coordinates?.length - 1]?.[0]
                    }
                  >
                    <div
                      className="map-marker"
                      style={{
                        transform: transform(
                          [
                            line?.coordinates?.[
                              line?.coordinates?.length - 2
                            ]?.[1],
                            line?.coordinates?.[
                              line?.coordinates?.length - 2
                            ]?.[0],
                          ],
                          [
                            line?.coordinates?.[
                              line?.coordinates?.length - 1
                            ]?.[1],
                            line?.coordinates?.[
                              line?.coordinates?.length - 1
                            ]?.[0],
                          ]
                        ),
                      }}
                    >
                      <img
                        src={Arrow}
                        alt="arrow"
                        style={{ height: "54px", width: "24px" }}
                      />
                    </div>
                  </Marker>
                )}

                {line?.leftArrow && viewport?.zoom > 17 && (
                  <Marker
                    latitude={line?.coordinates?.[0]?.[1]}
                    longitude={line?.coordinates?.[0]?.[0]}
                  >
                    <div
                      className="map-marker"
                      style={{
                        transform: transform(
                          [
                            line?.coordinates?.[1]?.[1],
                            line?.coordinates?.[1]?.[0],
                          ],
                          [
                            line?.coordinates?.[0]?.[1],
                            line?.coordinates?.[0]?.[0],
                          ]
                        ),
                      }}
                    >
                      <img
                        src={Arrow}
                        alt="arrow"
                        style={{ height: "54px", width: "24px" }}
                      />
                    </div>
                  </Marker>
                )}

                <Source
                  id={"source-" + line?.id}
                  type="geojson"
                  data={{
                    type: "Feature",
                    properties: {},
                    geometry: {
                      type: "LineString",
                      coordinates: line?.coordinates,
                    },
                  }}
                >
                  <Layer
                    id={"layer-" + line?.id}
                    type="line"
                    source="my-data"
                    layout={{
                      "line-join": "round",
                      "line-cap": "round",
                    }}
                    paint={{
                      "line-color": line?.selected
                        ? "rgba(0, 50, 250, 0.75)"
                        : "rgba(0, 50, 250, 0.5)",
                      "line-width": 3,
                      "line-dasharray": line?.selected ? [2, 2] : [],
                    }}
                  />
                </Source>
              </>
            )}
          </Fragment>
        ))}
    </Fragment>
  );
};

export default memo(MapLineDrawer);
