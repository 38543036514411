import { Tab } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabs({ names, panels }) {
  return (
    <div className="w-full max-w-md">
      <Tab.Group>
        <Tab.List className="flex border-t-2 border-b-2 border-gray-200 px-0.5">
          {names.map((name) => (
            <Tab
              key={name}
              className={({ selected }) =>
                classNames(
                  "w-full  py-2 text-sm text-dark font-medium leading-5 rounded-md",
                  selected
                    ? "bg-gray-200 outline outline-1 outline-gray-400"
                    : "hover:opacity-75"
                )
              }
            >
              {name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-3">
          {panels.map((panel, index) => (
            <Tab.Panel key={names[index]} className="bg-white/5 p-3">
              {panel}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
