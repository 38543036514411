import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  useMemo,
} from "react";
import AuthContext from "../AuthContext";
import Axios from "../api/api";
import EditImage from "./EditImage";
import EditHatch from "./EditHatch";
import Tabs from "./Tabs";
import { toast } from "react-toastify";

export default function StickerList({ onClickAdd }) {
  const [selectedSign, setSelectedSign] = useState(null);
  const [signs, setAllSignsData] = useState([]);

  const existingSigns = useMemo(() => {
    return signs.filter((d) => d.type === "existing").sort((a, b) => a.name.localeCompare(b.name));
  }, [signs]);

  const newSigns = useMemo(() => {
    return signs.filter((d) => d.type !== "existing" && d.type !== "hatch").sort((a, b) => a.name.localeCompare(b.name));
  }, [signs]);

  const hatchSigns = useMemo(() => {
    return signs.filter((d) => d.type === "hatch").sort((a, b) => a.name.localeCompare(b.name));
  }, [signs]);

  const auth = useContext(AuthContext);

  const handleDeletePrivateSticker = async (id) => {
    try {
      await Axios(auth).delete(`/api/signs/private/delete/${id}`);
      await fetchData();
      toast("Sticker deleted successfully.", { type: "success" });
    } catch (error) {
      console.log(error?.response);
    }
  };

  const onSelectSign = (sign) => {
    setSelectedSign(sign);
  };

  // Fetching Data of All Signs
  const fetchData = async () => {
    const [privateSignsResponse, signsResponse] = await Promise.all([
      Axios(auth).get(`/api/signs/private/get`),
      Axios(auth).get(`/api/signs/get`),
    ]);
    const signsList = [
      ...privateSignsResponse.data,
      ...signsResponse.data,
    ].sort((a, b) => a.name.localeCompare(b.name));

    setAllSignsData(signsList);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Fragment>
      <div className="p-0">
        <h3 className="px-2 text-xl mb-2">Sticker List</h3>
        <Tabs
          names={["Ny", "Eksisterende", "Afmærkning"]}
          panels={[
            <List
              signs={newSigns}
              auth={auth}
              onClickAdd={onClickAdd}
              onSelectSign={onSelectSign}
              onDeleteSticker={handleDeletePrivateSticker}
            />,
            <List
              signs={existingSigns}
              auth={auth}
              onClickAdd={onClickAdd}
              onSelectSign={onSelectSign}
              onDeleteSticker={handleDeletePrivateSticker}
            />,
            <List
              signs={hatchSigns}
              auth={auth}
              onClickAdd={onClickAdd}
              onSelectSign={onSelectSign}
              onDeleteSticker={handleDeletePrivateSticker}
            />,
          ]}
        />
      </div>
      {selectedSign &&
        (selectedSign?.type === "hatch" ? (
          <EditHatch
            onSave={() => fetchData()}
            onClose={() => setSelectedSign(null)}
            open={Boolean(selectedSign)}
            sign={selectedSign}
          />
        ) : (
          <EditImage
            onSave={() => fetchData()}
            onClose={() => setSelectedSign(null)}
            open={Boolean(selectedSign)}
            sign={selectedSign}
            signs={signs}
          />
        ))}
    </Fragment>
  );
}

const List = ({ signs, onClickAdd, onSelectSign, onDeleteSticker, auth }) => {
  const [search, setSearch] = useState("");

  const handleSearchChange = (value) => {
    setSearch(value);
  };

  const filtered = signs.filter(
    (sign) => sign.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
  );
  return (
    <Fragment>
      <input
        type="text"
        name="search"
        id="txt-search"
        value={search}
        onChange={(e) => handleSearchChange(e.target.value)}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mb-2"
        placeholder="Search ..."
      />
      <div
        className="max-h-screen overflow-y-auto"
        style={{ maxHeight: "72vh", overflow: "auto" }}
      >
        {filtered?.map((sign, index) => (
          <Sticker
            key={index}
            auth={auth}
            sign={sign}
            onClickAdd={onClickAdd}
            onSelectSign={onSelectSign}
            onDeleteSign={onDeleteSticker}
          />
        ))}
      </div>
    </Fragment>
  );
};

const Sticker = ({ sign, onClickAdd, onSelectSign, onDeleteSign, auth }) => {
  return (
    <div className="px-3 py-2 flex items-center hover:bg-gray-300 cursor-pointer group">
      {sign.image && (
        <img src={sign.image} alt={sign.name} className="w-8 border-1" />
      )}
      <span className="ml-3">{sign.name}</span>
      <div className="flex gap-2 ml-auto">
        {sign?.userId && sign?.userId == auth?.user?.userId && (
          <button
            className="hidden group-hover:block hover:bg-gray-400 rounded-full"
            onClick={() => onDeleteSign(sign?._id)}
          >
            <img
              src={require("../assets/delete.png")}
              className="w-5"
              alt="delete"
            />
          </button>
        )}
        <button
          className="hidden group-hover:block hover:bg-gray-400 rounded-full"
          onClick={() => onSelectSign(sign)}
        >
          <img
            src={require("../assets/editing.png")}
            alt="Edit"
            className="w-5"
          />
        </button>
        <button
          className="hidden group-hover:block hover:bg-gray-400 rounded-full"
          onClick={() => onClickAdd(sign)}
        >
          <img src={require("../assets/add.png")} alt="Add" className="w-5" />
        </button>
      </div>
    </div>
  );
};
