import React, { useState, useEffect, useContext } from "react";
import AddSign from "../components/AddSign";
import AuthContext from "../AuthContext";
import Axios from "../api/api";
import { toast } from "react-toastify";

function Signs() {
  const [signModalSetting, setSignModalSetting] = useState({
    isEdit: false,
    showModal: false,
    currentSign: null,
  });
  const [signs, setAllSignsData] = useState([]);
  const [updatePage, setUpdatePage] = useState(true);
  const auth = useContext(AuthContext);

  useEffect(() => {
    fetchSignsData();
  }, [updatePage]);

  // Fetching Data of All Signs
  const fetchSignsData = () => {
    Axios(auth)
      .get(`/api/signs/get`)
      .then((resp) => {
        setAllSignsData(resp.data);
      })
      .catch((err) => {
        setAllSignsData([]);
        console.log(err);
      });
  };

  // Modal for Sign Add
  const addSignModalSetting = () => {
    setSignModalSetting({
      isEdit: false,
      showModal: true,
      currentSign: null,
    });
  };

  const hideSignModal = () => {
    setSignModalSetting({ ...signModalSetting, showModal: false });
  };

  // Handle Page Update
  const handlePageUpdate = () => {
    setUpdatePage(!updatePage);
  };

  // Handle Page Update
  const handleClickDelete = (id) => {
    if (window.confirm("Click OK to delete the sign")) {
      Axios(auth)
        .delete(`/api/signs/delete/` + id)
        .then((res) => {
          if (res.status == 200) {
            toast("The item is deleted", { type: "success" });
            handlePageUpdate();
          } else {
            toast("Operation failed", { type: "error" });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  // Modal for Sign Edit
  const handleClickEdit = (sign) => {
    setSignModalSetting({
      isEdit: true,
      showModal: true,
      currentSign: sign,
    });
  };

  return (
    <>
      <div className=" flex flex-col gap-5 w-11/12">
        {signModalSetting.showModal && (
          <AddSign
            hideSignModal={hideSignModal}
            handlePageUpdate={handlePageUpdate}
            isEdit={signModalSetting.isEdit}
            currentSign={signModalSetting.currentSign}
          />
        )}
        {/* Table  */}
        <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 p-5">
          <div className="flex justify-between">
            <div className="flex gap-4 justify-center items-center px-3">
              <span className="font-bold">Signs</span>
            </div>
            <div className="flex gap-4">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 text-sm rounded"
                onClick={addSignModalSetting}
              >
                Add Sign
              </button>
            </div>
          </div>
          <table className="min-w-full divide-y-2 divide-gray-200 text-sm mt-2">
            <thead>
              <tr>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Image
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Sign Name
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Description
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Last Updated
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Action
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {signs.map((sign, index) => {
                return (
                  <tr key={sign._id}>
                    <td className="px-4 py-2">
                      {sign.image ? (
                        <img
                          src={sign.image}
                          alt={sign.name}
                          className="w-16 border-1"
                        />
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {sign.name}
                    </td>
                    <td className="px-4 py-2 text-gray-700">
                      {sign.description}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {new Date(sign.updatedAt).toLocaleDateString()}
                    </td>
                    <td className="px-4 py-2 text-gray-700">
                      <div className="flex items-center">
                        <img
                          src={require("../assets/editing.png")}
                          className="w-7 cursor-pointer mr-2"
                          alt="edit"
                          onClick={() => handleClickEdit(sign)}
                        />
                        <img
                          src={require("../assets/delete.png")}
                          className="w-7 cursor-pointer"
                          alt="delete"
                          onClick={() => handleClickDelete(sign._id)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Signs;
